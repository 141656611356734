module.exports = {
    siteUrl: `https://blog.sparkpay.pt`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Sparkpay Blog`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Notícias sobre Bitcoin em português. Saiba tudo o que queria saber sobre Bitcoin, blockchain e as tecnologias associadas.`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `Sparkpay`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
}
